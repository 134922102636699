import React, { useState } from "react";

const Dashboard = () => {
  const [loadingIframe, setLoadingIframe] = useState(true);
  const GetTokenURL = "https://os-token-loghub.chenhaiyun.online/gettoken";
  const EmbededURL =
    "https://os-token-loghub.chenhaiyun.online/_dashboards/app/dashboards?security_tenant=global#/view/5f57a00e-eb61-453e-87c3-34960b9a4856?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-15m%2Cto%3Anow))&show-query-input=true&show-time-filter=true";
  return (
    <div>
      {/* 隐藏 iframe，用于获取 token */}
      <iframe
        onLoad={() => {
          setLoadingIframe(false);
        }}
        title="gettoken"
        src={GetTokenURL}
        height="0"
        width="0"
      ></iframe>
      {/* 当 token 未设置时，显示 Loading 状态，可以用图标替换 */}
      {loadingIframe ? (
        <div>Loading...</div>
      ) : (
        <div>
          <h1>Token Generate Domain</h1>
          <iframe
            height="1000"
            width="100%"
            title="token"
            src={EmbededURL}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
