import React, { useState } from "react";

const Discover = () => {
  const [loadingIframe, setLoadingIframe] = useState(true);
  const GetTokenURL = "https://os-token-loghub.chenhaiyun.online/gettoken";
  const EmbededURL =
    "https://os-token-loghub.chenhaiyun.online/_dashboards/app/discover?security_tenant=global#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15m,to:now))&_a=(columns:!(_source),filters:!(),index:'1ceb1634-9a11-49dd-8681-d7efc8cca5d9',interval:auto,query:(language:kuery,query:''),sort:!())";
  return (
    <div>
      <iframe
        onLoad={() => {
          setLoadingIframe(false);
        }}
        title="gettoken"
        src={GetTokenURL}
        height="0"
        width="0"
      ></iframe>
      {loadingIframe ? (
        <div>Loading...</div>
      ) : (
        <div>
          <h1>Discover Page Iframe</h1>
          <iframe
            height="1000"
            width="100%"
            title="token"
            src={EmbededURL}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default Discover;
