import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Discover from "./pages/Discover";
import PostMessage from "./pages/PostMessage";
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div className="text-center">
        <h1>Open Search Embed Demo</h1>
      </div>
    ),
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/discover",
    element: <Discover />,
  },
  {
    path: "/alerting",
    element: <PostMessage />,
  },
]);
function App() {
  return (
    <div className="App">
      <ul className="top-header">
        <li>
          <a href="/">App Home Page</a>
        </li>
        <li>
          <a href="/dashboard">OpenSearch Dashobard</a>
        </li>
        <li>
          <a href="/discover">OpenSearch Discover</a>
        </li>
        <li>
          <a href="/alerting">Alerting Page</a>
        </li>
      </ul>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
